import { Container, Header, CardBodyText, Column, Row, ColumnTitle, ColumnValue } from "./styles";

interface ICardProps {
  title: string;
  width?: string;
  children?: React.ReactNode
}

const Card = ({ title, width, children }: ICardProps) => {
  return (
      <Container width={width}>
        <Header>
          <span>{title}</span>
        </Header>
        <CardBodyText>
        {children}
        </CardBodyText>
      </Container>
  );
}

export { Card, Column, Row, ColumnTitle, ColumnValue };