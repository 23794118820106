import { useHistory } from "react-router-dom";
import {FormEvent, useRef} from "react";
import {Container, DarkBackground, FormContainer, SubmitButton, InputContainer, StyledInput} from "./styles";

import PotencialImg from "../../assets/potencial.jpg";
import LogoImg from "../../assets/logo_grupo.png";

import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";


export const SignIn = () => {
  const history = useHistory();

  const { login } = useContext(AuthContext);

  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const handleSignIn = async (event: FormEvent) => {
    event.preventDefault();
    try {
      if (!emailInputRef?.current?.value || !passwordInputRef?.current?.value) {
        toast.error("Dados inválidos");
        return;
      }

      const schema = Yup.object().shape({
        email: Yup.string().required('O Email é obrigatório!').email("Informe um e-mail válido"),
        password: Yup.string().required('A Senha é obrigatória!'),
      });

      const data = {
        email: emailInputRef.current?.value,
        password: passwordInputRef.current?.value
      }

      await schema.validate(data, { abortEarly: false })

      await login(data);

      history.push("company_receita");

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.errors.map((er) => toast.error(er));
        return;
      }

      if (err.response?.data?.message) {
        toast.error(err.response.data.message);
        return;
      }
    }
  }

  return (
      <Container>
        <img src={PotencialImg} alt="background-potencial" />
        <DarkBackground>
          <FormContainer onSubmit={handleSignIn}>
            <img src={LogoImg} alt="logo-potencial" />
              <InputContainer>
                <StyledInput
                    ref={emailInputRef}
                    name="email"
                    placeholder="Informe o seu e-mail"
                />
              </InputContainer>
              <InputContainer>
                <StyledInput
                    ref={passwordInputRef}
                    name="password"
                    type="password"
                    placeholder="Informe a sua senha"
                />
              </InputContainer>
              <SubmitButton type="submit">
                <span>Acessar</span>
              </SubmitButton>
          </FormContainer>
        </DarkBackground>

      </Container>
  )
}

export default SignIn;