import { GlobalStyle } from "./styles/global";
import { ToastContainer } from 'react-toastify';
import { Routes } from "./routes";

import 'react-toastify/dist/ReactToastify.css';
import {AuthProvider} from "./context/AuthContext";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <GlobalStyle />
        <ToastContainer />
        <Routes />
      </AuthProvider>
    </div>
  );
}

export { App };
