import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { Card, Column, ColumnTitle, ColumnValue, Row } from "../../components/Card";
import { apiRapid } from "../../services/apiRapid";
import { Container, Title, PaginationContainer, PaginationText } from "./styles";

interface IParams {
  socio: string;
}

interface ICompanyData {
  tax_id: string;
  name: string;
}

interface IOwnerData {
  name: string,
  tax_id: string,
  company: ICompanyData;
}

interface IPaginationData {
  pagination: {
    total: number;
    count: number;
    per_page: number;
    current_page: number;
    total_pages: number;
    links: []
  }
}

const OwnerCompanies = () => {
  const { socio } = useParams<IParams>();  
  const history = useHistory();
  const [ownerCompanies, setOwnerCompanies] = useState<IOwnerData[]>([]);
  const [pagination, setPagination] = useState<IPaginationData>({} as IPaginationData);

  useEffect(() => {
    if (!socio) {
      alert("Informe um sócio válido!")
      
      history.push("/company_receita")

      return;
    }

    async function getData() {
      try {
        const response = await apiRapid.get(`/socio/${socio}`)

        const size = Object.keys(response.data).length;

        if (size === 1) {
          alert("Nenhum valor encontrado para essa pesquisa!");
          window.close();
          return;
        }

        const parsedData: IOwnerData[] = [];
        
        Object.keys(response.data).forEach((key) => {
          if (key !== "meta") {
            parsedData.push(response.data[key]);
          }

          if (key === "meta") {
            setPagination(response.data["meta"])
          }
        });

        setOwnerCompanies(parsedData);
      } catch (err) {
        window.close();
        toast.error("Erro Interno", err.message);
      }
    }

    getData();
  }, [history, socio]);


  return (
    <Container>
      {ownerCompanies.length > 0 && (
        <>
        <Title>Empresas do sócio: {ownerCompanies[0].name}</Title>
        <PaginationContainer>
          <PaginationText>{pagination.pagination.count} registro(s)</PaginationText>
        </PaginationContainer>
          {ownerCompanies.map(ownerCompany => (
            <Card key="Empresa" title="Empresa">
            <Column>
              <Row>
                <ColumnTitle withoutMargin>Sócio:</ColumnTitle>
                <ColumnValue>{ownerCompany.name}</ColumnValue>
              </Row>
              <Row>
                <ColumnTitle withoutMargin>Nome da empresa:</ColumnTitle>
                <ColumnValue>{ownerCompany.company.name}</ColumnValue>
              </Row>
              <Row>
                <ColumnTitle withoutMargin>CNPJ:</ColumnTitle>
                <ColumnValue>{ownerCompany.company.tax_id}</ColumnValue>
              </Row>
            </Column>
          </Card>
          ))}
        </>
      )}
    </Container>
  )
}

export { OwnerCompanies };