import {BrowserRouter as Router, Switch} from 'react-router-dom';

import { SignIn } from "../pages/SignIn";
import { CompanyReceita } from '../pages/CompanyReceita';
import { OwnerCompanies } from '../pages/OwnerCompanies';
import { PrivateRoutes } from './private.routes';
import { PublicRoutes } from './public.routes';


const Routes = () => (
      <Router >
          <Switch>
            <PublicRoutes exact path="/">
              <SignIn />
            </PublicRoutes>
            
            <PrivateRoutes path="/company_receita" component={CompanyReceita} />
            <PrivateRoutes path="/owner_companies/:socio" component={OwnerCompanies} />
            
          </Switch>
      </Router>
)

export { Routes };