import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  
  padding-top: 2rem;
  
  align-items: center;
  justify-content: center;
  
  flex-direction: column;
`;

export const Title = styled.span`
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--white);
`;

export const InputContainer = styled.div`
  display: flex;
  flex:1;
  width: 90%;
  height: 3rem;
  margin-top: 2rem;
  
  align-items: center;

  background: var(--lighter);

  border: 1px solid #d7d7d7;

  border-radius: 0.25rem;



  button {
    border: 0;
    height: 3rem;
    width: 2.5rem;
    
    &:hover {
      filter: brightness(0.9);
    }
    
    svg {
      color: var(--dark);
      height: 2rem;
      width: 2rem;
    }
  }
`;

export const Input = styled.input`
  display: flex;
  flex: 1;
  height: 3rem;
  padding: 0 1.5rem;
  background: var(--lighter);
  
  border: 0;
  
  font-weight: 400;
  font-size: 1rem;
  
  color: var(--darker);
  
  :focus {
    outline: none;
  }
`;


export const CardsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  width: 90%;
  
  
  justify-content: space-between;

  margin-top: 2rem;

  flex-wrap: wrap;
`;
