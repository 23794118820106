import { Container, Title, InputContainer, Input, CardsContainer } from "./styles";

import { Link } from "react-router-dom";

import { MdSearch } from "react-icons/md";
import { AxiosResponse } from "axios";

import { format } from "date-fns";

import {Card, Column, Row, ColumnTitle, ColumnValue} from "../../components/Card";
import {ChangeEvent, useState} from "react";
import {maskCnpj} from "../../utils/inputMask";
import {api} from "../../services/api";

export interface AtividadePrincipal {
  text: string;
  code: string;
}

export interface AtividadesSecundaria {
  text: string;
  code: string;
}

export interface Qsa {
  qual: string;
  nome: string;
}

export interface Extra {
}

export interface Billing {
  free: boolean;
  database: boolean;
}

export interface ResponseReceita {
  atividade_principal: AtividadePrincipal[];
  data_situacao: string;
  tipo: string;
  nome: string;
  uf: string;
  telefone: string;
  email: string;
  atividades_secundarias: AtividadesSecundaria[];
  qsa: Qsa[];
  situacao: string;
  bairro: string;
  logradouro: string;
  numero: string;
  cep: string;
  municipio: string;
  porte: string;
  abertura: string;
  natureza_juridica: string;
  fantasia: string;
  cnpj: string;
  ultima_atualizacao: Date;
  status: string;
  complemento: string;
  efr: string;
  motivo_situacao: string;
  situacao_especial: string;
  data_situacao_especial: string;
  capital_social: string;
  extra: Extra;
  billing: Billing;
}


const CompanyReceita = () => {

  const [cnpj, setCnpj] = useState('');
  const [companyData, setCompanyData] = useState<ResponseReceita>({} as ResponseReceita)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const maskedCnpj = maskCnpj(e.target.value);
    setCnpj(maskedCnpj);
  }

  const search = async () => {
    const parsedCnpj = cnpj.replaceAll(".", "").replace("/", "").replace("-", "");

    const response: AxiosResponse<ResponseReceita> = await api.get(`/company_receita/${parsedCnpj}`);

    setCompanyData(response.data);
  }

  return (
      <>
      <Container>
        <Title>Prospecção de clientes - Esses dados estão sendo buscados na receita federal</Title>

            <InputContainer>
              <Input
                  name="search"
                  placeholder="Informe um cnpj para pesquisar na receita federal"
                  onChange={onChange}
                  value={cnpj}
                  maxLength={18}
              />


              <button type="button" onClick={search}>
                <MdSearch  />
              </button>
            </InputContainer>
            {companyData?.atividade_principal?.length > 0 && (
              <>
                <CardsContainer>
                  <Card key="Atividade Principal" title="Atividade Principal">
                    <Column>
                      <Row>
                        <ColumnValue>{companyData.atividade_principal[0].text}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle withoutMargin>Cod:</ColumnTitle>
                        <ColumnValue>{companyData.atividade_principal[0].code}</ColumnValue>
                      </Row>
                    </Column>
                  </Card>
                  <Card key="Dados Principais" title="Dados Principais">
                    <Column>
                      <Row>
                        <ColumnTitle>Abertura</ColumnTitle>
                        <ColumnValue>{companyData.abertura}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>Data situação</ColumnTitle>
                        <ColumnValue>{companyData.data_situacao}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>Situação:</ColumnTitle>
                        <ColumnValue>{companyData.situacao}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>Tipo:</ColumnTitle>
                        <ColumnValue>{companyData.tipo}</ColumnValue>
                      </Row>
                    </Column>
                    <Column>
                      <Row>
                        <ColumnTitle>Nome: </ColumnTitle>
                        <ColumnValue>{companyData.nome}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>Fantasia: </ColumnTitle>
                        <ColumnValue>{companyData.fantasia}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>Porte: </ColumnTitle>
                        <ColumnValue>{companyData.porte}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>CNPJ: </ColumnTitle>
                        <ColumnValue>{companyData.cnpj}</ColumnValue>
                      </Row>
                    </Column>
                  </Card>
                  <Card key="Localização e Contato" title="Localização e Contato">
                    <Column>
                      <Row>
                        <ColumnTitle>Município</ColumnTitle>
                        <ColumnValue>{companyData.municipio}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>Bairro</ColumnTitle>
                        <ColumnValue>{companyData.bairro}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>Cep:</ColumnTitle>
                        <ColumnValue>{companyData.cep}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>UF:</ColumnTitle>
                        <ColumnValue>{companyData.uf}</ColumnValue>
                      </Row>
                    </Column>
                    <Column>
                      <Row>
                        <ColumnTitle>Logradouro: </ColumnTitle>
                        <ColumnValue>{companyData.logradouro}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>Número: </ColumnTitle>
                        <ColumnValue>{companyData.numero}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>Telefone: </ColumnTitle>
                        <ColumnValue>{companyData.telefone}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle>E-mail: </ColumnTitle>
                        <ColumnValue>{companyData.email}</ColumnValue>
                      </Row>
                    </Column>
                  </Card>
                  <Card key="Atividades secundárias" title="Atividades secundárias">
                    <Column>
                      {companyData.atividades_secundarias.map((atividadeSecundaria) => (
                          <Row key={atividadeSecundaria.code}>
                            <ColumnTitle withoutMargin>{atividadeSecundaria.code}</ColumnTitle>
                            <ColumnValue>{atividadeSecundaria.text}</ColumnValue>
                          </Row>
                      ))}
                    </Column>
                  </Card>
                  <Card key="Qsa" title="Qsa">
                    <Column>
                      {companyData.qsa.map((qsa) => (
                        <Row key={qsa.qual + qsa.nome}>
                          <ColumnTitle withoutMargin >{qsa.qual}</ColumnTitle>
                          <ColumnValue>
                            <Link to={`/owner_companies/${qsa.nome}`} target="_blank">
                              {qsa.nome}
                            </Link>
                          </ColumnValue>
                        </Row>
                      ))}
                    </Column>
                  </Card>
                  <Card key="Outras informações" title="Outras informações">
                    <Column>
                      <Row>
                        <ColumnTitle withoutMargin >Natureza Jurídica:</ColumnTitle>
                        <ColumnValue>{companyData.natureza_juridica}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle withoutMargin >Última Atualização: </ColumnTitle>
                        <ColumnValue>{format(new Date(companyData.ultima_atualizacao), "dd/MM/yyyy")}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle withoutMargin >Status: </ColumnTitle>
                        <ColumnValue>{companyData.status}</ColumnValue>
                      </Row>
                      <Row>
                        <ColumnTitle withoutMargin >Capital Social: </ColumnTitle>
                        <ColumnValue>{companyData.capital_social}</ColumnValue>
                      </Row>
                    </Column>
                  </Card>
                </CardsContainer>
              </>
            )}
      </Container>
      </>
  );
}

export { CompanyReceita };