import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 2rem;
`;

export const Title = styled.span`
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--white);

  margin-bottom: 1rem;
`;

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const PaginationText = styled.span`
  font-size: 1rem;
  color: var(--white);
  margin-bottom: 1rem;
`;