import { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';


const PublicRoutes = ({ component: Component, ...rest}: RouteProps) => {
    const { isAuthenticated } = useContext(AuthContext);

    if (isAuthenticated()) {
      return (
        <Route 
          {...rest}>
          <Redirect 
            to="/company_receita"
          />
        </Route>
      )
    }

    return (
      <Route 
        {...rest}
        component={Component}
      />  
    )

}

export { PublicRoutes };