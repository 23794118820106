import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { MdExitToApp } from "react-icons/md"

import GroupLogoImg from "../../assets/logo_grupo.png";

const Header = () => {
  const history = useHistory();

  const handleLogoff = () => {

    const isConfirmated = window.confirm("Tem certeza que deseja sair?");

    if (isConfirmated) {
      localStorage.clear();

      history.push("/");
    }
  }

  return (
      <Container>
        <img src={GroupLogoImg} alt={"logo-grupo"} />

        <button onClick={handleLogoff}>
          <MdExitToApp />
        </button>
      </Container>
  )
};

export { Header };