import styled from "styled-components";

export interface IHeaderProps {
  width?: string;
}

interface IColumnTitleProps {
  withoutMargin?: boolean;
}

export const Container = styled.div<IHeaderProps>`
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;
  
  width: ${props => props.width ? props.width : "100%"};
  border-radius: 8px;
  background: var(--lighter);
  
  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  
  width: 100%;
  height: 2rem;
  
  background: var(--green);
  
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  
  align-items: center;
  
  padding-left: 0.5rem;
  
  span {
    color: var(--white);
    font-weight: 600;
  }
`;

export const CardBodyText = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  justify-content: space-between;

  padding: 0.5rem;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  
  & + div {
    margin-top: 0.5rem;
  }

  @media (max-width: 720px) {
    width: 100%;
    flex-direction: column;

  }
`;

export const ColumnTitle = styled.div<IColumnTitleProps>`
  display: flex;
  width: ${props => props.withoutMargin ? "auto" : "30%"};
  color: var(--dark);
`;

export const ColumnValue = styled.div`
  display: flex;
  font-weight: 600;
  color: var(--dark);
  margin-left: 0.2rem;
`;