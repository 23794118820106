import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 3rem;
  background: #181818;
  
  padding: 1rem;
  
  align-items: center;
  justify-content: space-between;

  box-shadow: 0 8px 6px -6px black;
  
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  
  img {
    height: 2rem;
  }
  
  button {
    background: transparent;
    border: 0;
    height: 2rem;

    &:hover {
      filter: brightness(0.9);
    }
    
    svg {
      color: white;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;