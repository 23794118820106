import {createContext, ReactNode} from "react";
import { toast } from "react-toastify";
import { api } from "../services/api";

import {  } from "jsonwebtoken";

interface ILoginData {
  email: string;
  password: string;
}

interface IAuthContext {
  login: (data: ILoginData) => void;
  isAuthenticated: () => boolean;
}

interface IAuthContextProps {
  children: ReactNode;
}

const sessionStorageKey = "@Potencial:token";

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function AuthProvider({ children }: IAuthContextProps) {
  async function login({ email, password }: ILoginData) {
    try {
    const response = await api.post("/sessions", { email, password });

    localStorage.setItem(sessionStorageKey, response.data.token)

    } catch (err) {
      if (err.response?.data?.message) {
        toast.error(err.response.data.message);
        return;
      }
      toast.error(err.message);
    }
  }

  function isAuthenticated():boolean {
    const sessionToken = localStorage.getItem(sessionStorageKey);

    if (sessionToken) {
      return true;
    }
    return false;
  }

  return (
    <AuthContext.Provider value={{ login, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}