import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const DarkBackground = styled.div`
  display: flex;
  flex: 1;
  background: rgba(0, 0, 0, 0.51);
  
  z-index: 1;
  
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.form`
  display: flex;
  width: 30%;

  align-items: center;
  justify-content: space-evenly;

  flex-direction: column;

  img {
    width: 40%;

    margin-bottom: 2rem;
  }

  @media(max-width: 1000px) {
    width: 50%;
  }

  @media(max-width: 720px) {
    width: 90%;
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 3rem;

  border-radius: 8px;
  border: 0;
  
  height: 3rem;
  width: 90%;
  
  background: var(--green);
  
  span {
    color: var(--white);
    font-size: 18px;
    font-weight: 600;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex:1;
  width: 90%;
  height: 3rem;
  margin-top: 2rem;
  
  align-items: center;

  background: var(--lighter);

  border: 1px solid #d7d7d7;

  border-radius: 0.25rem;



  button {
    border: 0;
    height: 3rem;
    width: 2.5rem;
    
    &:hover {
      filter: brightness(0.9);
    }
    
    svg {
      color: var(--dark);
      height: 2rem;
      width: 2rem;
    }
  }
`;

export const StyledInput = styled.input`
  display: flex;
  flex: 1;
  height: 3rem;
  padding: 0 1.5rem;
  background: var(--lighter);
  
  border: 0;
  
  font-weight: 400;
  font-size: 1rem;
  
  color: var(--darker);
  
  :focus {
    outline: none;
  }
`;