import axios from "axios";

const apiRapid = axios.create({
  baseURL: process.env.REACT_APP_RAPID_API_BASE_URL,
  headers: {
    'x-rapidapi-key': process.env.REACT_APP_RAPID_API_TOKEN
  }
  
})

export { apiRapid };