import { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { Header } from '../components/Header';
import { AuthContext } from '../context/AuthContext';


const PrivateRoutes = ({ component: Component, ...rest}: RouteProps) => {
    const { isAuthenticated } = useContext(AuthContext);

    if (!isAuthenticated()) {
      return (
        <Route 
          {...rest}>
          <Redirect 
            to="/"
          />
        </Route>
      )
    }

    return (
      <>
        <Header />
        <Route 
          {...rest}
          component={Component}
        />  
      </>
    )

}

export { PrivateRoutes };